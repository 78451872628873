import React, { useContext, useRef, useState, useEffect } from 'react';
import { ComboBox, MenuButton, Menu, MenuItem, MenuSep } from 'rc-easyui';
import { util } from '../data/shared';
import Cookies from 'js-cookie';
import config from '../config.json';
import { log } from '../data/logger';
import { SessionContext } from '../data/Session';

function MenuHidable(props) {
	if (props.hide !== true) {
		return <MenuItem {...props} ></MenuItem>
	} else {
		return null;
	}
}

export const AcctMenu = ({style, ...props}) => {
	const { ChatToggle, GotoPage, HasPerm, isAuth, signout, t, updatePref, User } = useContext(SessionContext);

	function setAcct(val) {
		let v = val.split(";");
		switch (v[0]) {
			case "logout":
				signout();
				break;
			case "pref":
				GotoPage("/account/prefs", t("lbl_prefernces"));
				break;
			case "press":
				GotoPage("/account/press", t("lbl_access_press"));
				break;
			case "admin":
				GotoPage("/account/admin", t("lbl_admin"));
				break;
			case "chat":
				ChatToggle();
				break;
			case "cust":
				updatePref({ customer_index: v[1] });
				break;
			case "reperr":
				GotoPage("/errrep");
				break;
			default:
				log.trace("menu", "no AcctMenu option %s", val);
		}
	}
	return (
		isAuth &&
		<MenuButton text={props.smallscreen ? "" : t("lbl_account")} style={style} tabIndex={-1}
			plain iconCls="fas fa-user" menu={AcctMenuDrop({ HasPerm, t, User})} onMenuItemClick={setAcct}
		/>
	);
}

function AcctMenuDrop({ HasPerm, t, User}) {
	function theMenu() {
		return (
			<Menu>
				<MenuHidable hide={User.isSales} text={t("lbl_prefernces")} iconCls="fas fa-cog" value="pref" />
				<MenuHidable hide={!HasPerm("press")} text={t("lbl_access_press")} iconCls="fas fa-gopuram" value="press" />
				<MenuHidable hide={!HasPerm("admin")} text={t("lbl_admin")} iconCls="fas fa-shield-alt" value="admin" />
				<MenuHidable hide={User.isSales} text={t("lbl_helpchat")} iconCls="fas fa-comments" value="chat" />
				<MenuItem text={t("lbl_reperr")} iconCls="fas fa-random" value="reperr" />
				<MenuSep />
				<MenuItem text={t("lbl_logout")} iconCls="fas fa-sign-out-alt" value="logout" />
			</Menu>
		);
	}

	return theMenu;
}

export const CustMenu = ({style, }) => {
	const { isAuth, updatePref, User} = useContext(SessionContext);
	const cboBox = useRef();
	const [currCust, setCurrCust] = useState((User.customer_index!==false ? User.customer_list[User.customer_index].customer_number : ""));

	useEffect(() => {
		if(User.customer_index === false) return;
		setCurrCust(User.customer_list[User.customer_index].customer_number);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [User.customer_index])

	function setCust(val) {
		setCurrCust(val)
		if(val) {
			updatePref({ customer_index: User.customer_list.findIndex(r=>r.customer_number === val) });
		} else {
			setTimeout(() => {
				setCurrCust(User.customer_list[User.customer_index].customer_number);
			}, 100);
		}
	}
	//smallscreen gets ??
	//!props.smallscreen &&
	return (
		isAuth && 
		<ComboBox style={{ width:"100%", ...style}} panelStyle={{ height: 'auto', maxHeight:window.document.body.clientHeight }}
			ref={ref=>cboBox.current=ref}
			tabIndex={-1}
			data={User.customer_list}
			valueField="customer_number"
			textField="name"
			value={currCust}
			iconCls="fas fa-address-card" 
			onChange={(value) => setCust(value)} 
			onFocus={() => {
				if(navigator.userAgent.match(/Edge/)) { //Edge needs a little break for calling select
					return setTimeout( cboBox.current && cboBox.current.inputRef.select.bind( cboBox.current.inputRef ), 10);
				}
				cboBox.current && cboBox.current.inputRef.select();
			}}
		/>
	);
}

export const LangMenu = ({style, ...props}) => {
	const {updatePref, User} = useContext(SessionContext);

	function setLang(val) {
		updatePref({ lang_pref: val });
	}
	//smallscreen gets icon only
	return (
		<MenuButton text={props.smallscreen ? "" : LangSelected(User)} style={style} tabIndex={-1}
			plain iconCls="fas fa-globe-americas" menu={LangMenuDrop(User)} onMenuItemClick={setLang}
		/>
	);
}

function LangSelected(User) {
	if(User.lang_pref === "es") {
		return "Español";
	} else {
		return "English";
	}
}

function LangMenuDrop(User) {
	function theMenu() {
		return (
			<Menu>
				<MenuItem text="English" iconCls={"far fa-" + (User.lang_pref === "en" ? "check-" : "") + "circle"} value="en" ></MenuItem>
				<MenuItem text="Español" iconCls={"far fa-" + (User.lang_pref === "es" ? "check-" : "") + "circle"} value="es"></MenuItem>
			</Menu>
		);
	}

	return theMenu;
}

export const UnitMenu = ({style, ...props}) => {
	const { isAuth, t, updatePref, User} = useContext(SessionContext);

	function setUnit(val) {
		updatePref({ units_pref: val });
	}
	//smallscreen gets icon only
	return (
		isAuth &&
		<MenuButton text={props.smallscreen ? "" : t("lbl_unit"+User.units_pref)} tabIndex={-1}
			plain iconCls="fas fa-ruler" menu={UnitMenuDrop({t, User})} onMenuItemClick={setUnit} style={style}
		/>
	);
}

function UnitMenuDrop({t, User}) {

	function theMenu() {
		return (
			<Menu>
				{config.units.map((u) =>
					<MenuItem key={u.unit} text={t(u.phrase)} iconCls={"far fa-" + (User.units_pref === u.unit ? "check-" : "") + "circle"} value={t(u.unit)}></MenuItem>
				)}
			</Menu>
		);
	}
	return theMenu;
}

export const WorkOrderMenu = (props) => {
	let style = {};
	function setWorkOrder(val) {
		switch (val) {
			case "detail":
				props.onDetail && props.onDetail();
				break;
			case "retool":
				props.onRetool && props.onRetool();
				break;
			case "drawing":
				props.onDrawing && props.onDrawing();
				break;
			case "reorder":
				props.onReorder && props.onReorder();
				break;
			default:
				log.trace("menu", "no setWorkOrder option %s", val);
		}
	}
	
	if(props.hidden) {
		style.display = "none";
	}
	return (
		<MenuButton plain iconCls="fas fa-tasks" style={style} menu={WorkOrderMenuDrop(props)} onMenuItemClick={setWorkOrder}></MenuButton>
	);
}

function WorkOrderMenuDrop(props) {
	const t = props.t;
	function theMenu() {
		return (
			<Menu>
				<MenuHidable hide={typeof props.onDetail !== "function"} text={t("lbl_vieworder")} iconCls="fas fa-file-alt" value="detail" />
				<MenuHidable hide={typeof props.onRetool !== "function"} text={t("lbl_retool")} iconCls="fas fa-tools" value="retool" />
				<MenuHidable hide={typeof props.onDrawing !== "function" || props.hasdrawing === false} text={t("lbl_drawing")} iconCls="fas fa-image" value="drawing" />
				<MenuHidable hide={typeof props.onDrawing !== "function" || props.hasdrawingsingle === false} text={t("lbl_drawing_single")} iconCls="fas fa-image" value="drawing" />
				<MenuHidable hide={!util.isdevsystem() || typeof props.onReorder !== "function" || props.canreorder === false} text={t("lbl_reorder")} iconCls="fas fa-file-invoice" value="reorder" />
			</Menu>
		);
	}

	return theMenu;
}

export const QuoteMenu = (props) => {
	function setQuote(val) {
		switch (val) {
			case "detail":
				props.onDetail();
				break;
			case "pdf":
				props.onPDF();
				break;
			default:
				log.trace("menu", "no setQuote option %s", val);
		}
	}
	return (
		<MenuButton plain iconCls="fas fa-tasks" menu={QuoteMenuDrop(props)} onMenuItemClick={setQuote}></MenuButton>
	);
}

function QuoteMenuDrop(props) {
	const t = props.t;
	function theMenu() {
		return (
			<Menu>
				<MenuItem text={t("lbl_viewquote")} iconCls="fas fa-file-alt" value="detail" ></MenuItem>
				<MenuItem text={t("lbl_pdfquotedl")} iconCls="fas fa-file-pdf" value="pdf" ></MenuItem>
			</Menu>
		);
	}

	return theMenu;
}

export const SideBarMenu = ({ HasPerm, isAuth, t }) => {
	return AppMenu({ HasPerm, isAuth, t , item: "SideBar"});
};

function AppMenu({ HasPerm, isAuth, t, item }) {
	let mnu = [];
	if (isAuth === false) {
		return [{
			text: t("lbl_general"),
			state: "open",
			iconCls: "fas fa-layer-group",
			children: [ {
					text: t("lbl_mainsite"),
					iconCls: "tree-hit fas fa-external-link-alt",
					type: "url",
					url: "http://wilsonmfg.com"
				}, {
					text: t("login"),
					iconCls: "tree-hit fas fa-sign-in-alt",
					type: "nav",
					path: "/login"
				}, {
					text: t("lbl_forms"),
					iconCls: "tree-hit fas fa-align-left",
					type: "nav",
					path: "/forms"
				}, {
					text: t("lbl_brochures"),
					iconCls: "tree-hit fas fa-object-group",
					type: "nav",
					path: "/brochures"
				}
			]
		}];
	}

	switch (item) {
		case "SideBar":{
			const mnuGeneral = {
				text: t("lbl_general"),
				state: "open",
				iconCls: "fas fa-layer-group",
				children: []
			};
			if(Cookies.get("seenv") !== config.version) {
				mnuGeneral.children.push({
					text: t("lbl_news"),
					iconCls: "tree-hit fas fa-info-circle color-orangered",
					type: "nav",
					path: "/news"
				});
			}
			mnuGeneral.children.push({
				text: t("lbl_summary"),
				iconCls: "tree-hit fas fa-clipboard-list",
				type: "nav",
				path: "/summary"
			});
			mnuGeneral.children.push({
				text: t("lbl_contacts"),
				iconCls: "tree-hit fas fa-address-book",
				type: "nav",
				path: "/contacts"
			});
			mnuGeneral.children.push({
				text: t("lbl_forms"),
				iconCls: "tree-hit fas fa-align-left",
				type: "nav",
				path: "/forms"
			});
			mnuGeneral.children.push({
				text: t("lbl_brochures"),
				iconCls: "tree-hit fas fa-object-group",
				type: "nav",
				path: "/brochures"
			});

			//note:  if the EULA checkbox is not checked (typically because no EULA is signed) these permissions aren't granted
			if (HasPerm("order") || HasPerm("roquote") || HasPerm("quote")) {
				mnuGeneral.children.push({
					text: t("lbl_search"),
					iconCls: "tree-hit fas fa-search",
					type: "nav",
					path: "/search"
				});
			}
			//note:  if the EULA checkbox is not checked (typically because no EULA is signed) these permissions aren't granted
			if (HasPerm("order")) {
				if (HasPerm("billing")) {
					mnuGeneral.children.push(
						{
							text: t("lbl_invoicing"),
							iconCls: "tree-hit fas fa-file-invoice-dollar",
							type: "nav",
							path: "/billing"
						}
					);
				}
				mnuGeneral.children.push({
					text: t("lbl_orders"),
					iconCls: "tree-hit fas fa-file-invoice",
					type: "nav",
					path: "/orders"
				});
				mnuGeneral.children.push({
					text: t("lbl_favorites"),
					iconCls: "tree-hit fas fa-star",
					type: "nav",
					path: "/favorites"
				});
				mnuGeneral.children.push({
					text: t("lbl_material"),
					iconCls: "tree-hit fas fa-toilet-paper",
					type: "nav",
					path: "/materials"
				});
			}
			//note:  if the EULA checkbox is not checked (typically because no EULA is signed) these permissions aren't granted
			if (HasPerm("roquote") || HasPerm("quote")) {
				mnuGeneral.children.push({
					text: t("lbl_access_roquote"),
					iconCls: "tree-hit fas fa-search-dollar",
					type: "nav",
					path: "/quotes"
				});
			}
			mnuGeneral.children.push({
				text: t("lbl_filemanager"),
				iconCls: "tree-hit fas fa-folder-open",
				type: "nav",
				path: "/files"
			});
			if (HasPerm("taper")) {
				mnuGeneral.children.push({
					text: t("lbl_tapercalc"),
					iconCls: "tree-hit fas fa-calculator",
					type: "nav",
					path: "/taper"
				});
			}
			if(Cookies.get("seenv") === config.version) {
				mnuGeneral.children.push({
					text: t("lbl_news"),
					iconCls: "tree-hit fas fa-info-circle",
					type: "nav",
					path: "/news"
				});
			}
			mnuGeneral.children.push({
				text: t("lbl_help"),
				iconCls: "tree-hit fas fa-question-circle",
				type: "nav",
				path: "/help"
			});
			mnu.push(mnuGeneral);
			}break;
		default:
			log.trace("menu", "no menu %s", item);
	}

	return mnu;
}
